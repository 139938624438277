
import { computed } from 'vue'
import { useMapGetters } from 'public/src/pages/store_pages/hooks/useVuex.js'

export const useGetChoiceLabelImg = () => {
  const { locals = {} } = useMapGetters(['locals'])
  const { SiteUID, appLanguage } = typeof window !== 'undefined' ? gbCommonInfo : locals.value
  const choiceLabelImg = computed(() => {
    const tag = `${SiteUID}_${appLanguage}`
    switch (tag) {
      case 'mjp_ja':
        return '//img.ltwebstatic.com/images3_ccc/2024/09/19/86/1726716361096fa709771cbdb0af2a12700341be48.png'
      case 'mbr_pt-br':
        return '//img.ltwebstatic.com/images3_ccc/2024/11/28/53/17327743687604bb2d1ea096dcd46049bbd3935708.png'
      case 'pwmx_es':
        return '//img.ltwebstatic.com/images3_ccc/2024/11/28/e8/173277441467cb3480bf6c6d574b98febd484027f8.png'
      default:
        return '//img.ltwebstatic.com/images3_ccc/2024/07/12/2e/1720753743224f917b7f46982c88d6e5ff7fdf8dc8.png'
    }
  })
  return {
    choiceLabelImg,
  }
}
