import ClientOnly from 'vue-client-only'
import lazyComponent from 'public/src/services/lazyComponent/lazy-hydrate' // 组件懒加载
// import StaticImage from '../components/static-image/index.vue'
import CouponPackage from '../components/coupon-package/CouponPackage.vue'
import ShopBanner from '../components/shop-banner/ShopBanner.vue'
const componentsMap = {
  // 单图
  'StaticImage': () => import(/* webpackChunkName: "ccc-static-image" */'../components/static-image/StaticImageFactory'),
  // 图片组件 卡片控制组件
  'StaticImageCardController': () => import(/* webpackChunkName: "ccc-static-image" */'../components/static-image-card-controller/index.vue'),
  // 图文组件
  'TextEdit': () => import(/* webpackChunkName: "ccc-text-edit" */'../components/text-edit/index.vue'),
  // 纵向列表
  'VerticalItems': () => import(/* webpackChunkName: "ccc-vertical-items" */'../components/verticalItems/index.vue'),
  // 横向列表
  'HorizontalItems': () => import(/* webpackChunkName: "ccc-horizontal-items" */'../components/horizontal-items-entry/index.vue'),
  // 上图下商品组件
  // 'AbovePictureItems': () => import(/* webpackChunkName: "ccc-above-picture-items" */'../components/AbovePictureItems'),
  // 左图右商品组件
  'LeftPictureItems': () => import(/* webpackChunkName: "ccc-left-picture-items" */'../components/left-picture-items/index.vue'),
  // dailynew 组件
  'DailyNewItems': () => import(/* webpackChunkName: "ccc-daily-new-items" */'../components/daily-new/index'),
  // 通用券 / 店铺券   横向 / 纵向
  'HorizontalCoupon': () => import(/* webpackChunkName: "ccc-coupon-list" */'../components/coupon/CouponList'),
  'VerticalCoupon': () => import(/* webpackChunkName: "ccc-coupon-list" */'../components/coupon/CouponList'),
  'StoreHorizontalCoupon': () => import(/* webpackChunkName: "ccc-store-coupon-list" */'../components/store-page/store-coupon/CouponList.vue'),
  'StoreVerticalCoupon': () => import(/* webpackChunkName: "ccc-store-coupon-list" */'../components/store-page/store-coupon/CouponList.vue'),
  // 闪购
  'FlashSale': () => import(/* webpackChunkName: "flash-sale" */'../components/flash-sale/FlashSale'),
  // 闪购
  'FlashSaleBanner': () => import(/* webpackChunkName: "flash-sale" */'../components/flash-sale/FlashSale'),
  // 左图闪购
  'LeftFlashSale': () => import(/* webpackChunkName: "flash-sale" */'../components/flash-sale/FlashSale'),
  // 店招
  ShopBanner, // TODO: 改为非CCC加载
  // 'ShopBanner': () => import(/* webpackChunkName: "ccc-shop-banner" */'../components/shop-banner/ShopBanner.vue'),
  // 店铺促销活动组件
  'ShopActivity': () => import(/* webpackChunkName: "ccc-shop-activity" */'../components/store-page/shop-activity/index.vue'),
  // 商品列表组件
  'CategoryItemsDynamic': () => import(/* webpackChunkName: "ccc-category-items-dynamic" */'../components/store-page/category-items-dynamic/index.vue'),
  // 滑动多图组件(轮播图、横滑2.5、横滑4、横滑5)
  'SideslipImage': () => import(/* webpackChunkName: "ccc-sideslip-image" */'../components/sideslip-image-with-abt/index.vue'),
  // 倒计时
  'Countdown': () => import(/* webpackChunkName: "ccc-countdown" */'../components/countdown/Countdown.vue'),
  // 日历
  'Calendar': () => import(/* webpackChunkName: "ccc-calendar" */'../components/calendar/Calendar.vue'),
  // 文字、图片锚点
  'HorizontalAnchor': () => import(/* webpackChunkName: "horizontal-anchor" */'../components/anchor/index'),
  'HorizontalAnchorImage': () => import(/* webpackChunkName: "horizontal-anchor" */'../components/anchor/index'),
  // 排行榜入口
  'RankingEntrance': () => import(/* webpackChunkName: "ccc-ranking-entrance" */'../components/rank/RankingEntranceEntry'),
  // 排行榜入口 2卡
  'RankingEntranceList2': () => import(/* webpackChunkName: "ccc-ranking-entrance-list2" */'../components/rank/RankingEntranceList2'),
  // 动态排行榜组件入口
  'RankingEntranceList2Home': () => import(/* webpackChunkName: "ccc-ranking-entrance-list2-home" */'../components/rank/RankingEntranceList2Home'),
  // 排行榜
  'RankingList': () => import(/* webpackChunkName: "ccc-ranking-list" */'../components/rank/RankingList'),
  // 视频组件
  'Video': () => import(/* webpackChunkName: "ccc-video" */'../components/video/index.vue'),
  // 领积分
  'Integral': () => import(/* webpackChunkName: "ccc-integral" */'../components/integral/index.vue'),
  // 订阅组件
  'Subscribe': () => import(/* webpackChunkName: "ccc-subscribe" */'../components/subscribe/index.vue'),
  // 意见收集组件
  'Survey': () => import(/* webpackChunkName: "ccc-survey" */'../components/survey/index.vue'),
  // 意见收集组件
  'RecommendFlow': () => import(/* webpackChunkName: "ccc-recommend-flow" */'../components/recommend-flow/Index.vue'),
  // PK组件
  'PK': () => import(/* webpackChunkName: "ccc-pk" */'../components/pk/index.vue'),
  // 付费会员组件
  'Prime': () => import(/* webpackChunkName: "ccc-club" */'../components/prime/index.vue'),
  // 凑单免邮组件
  'FreeShippingInfo': () => import(/* webpackChunkName: "ccc-free-shipping" */ '../components/free-shipping/Index.vue'),
  // 排行榜推荐组件
  'RankingEntranceRecommend': () => import(/* webpackChunkName: "ccc-ranking-entrance-recommend" */'../components/RankingEntranceRecommend.vue'),
  // 品牌推荐组件
  'BrandRecommend': () => import(/* webpackChunkName: "ccc-brand-recommend" */'../components/brand-recommend/index'),
  // 系列搭配
  'LookBook': () => import(/* webpackChunkName: "ccc-look-book" */'../components/look-book/LookBook'),
  // 店铺卡片列表组件
  'StoreCardList': () => import(/* webpackChunkName: "ccc-store-card-list" */'../components/store/card-list/index.vue'),
  'StoreHorizontalCardList': () => import(/* webpackChunkName: "ccc-store-card-list" */'../components/store/card-list/index.vue'),
  // 店铺单卡片组件
  'StoreSingleCard': () => import(/* webpackChunkName: "ccc-store-single-card" */'../components/store/single-card/index.vue'),
  // 多店铺横滑组件
  'StoreMultiSlide': () => import(/* webpackChunkName: "ccc-store-multi-slide" */'../components/store/multi-slide/index.vue'),
  // 多店铺一店一品组件
  StoreMultiSlideOne: () => import(/* webpackChunkName: "ccc-store-multi-slide-one" */'../components/store/multi-slide-one/index.vue'),
  // 多店铺圆角卡片组件
  StoreMultiSlideCard: () => import(/* webpackChunkName: "ccc-store-multi-slide-card" */'../components/store/multi-slide-card/index.vue'),
  // 新品类热度推荐组件(sbc: suit by category)
  'CategoryRecommendDynamic': () => import(/* webpackChunkName: "ccc-category-recommend-dynamic" */'../components/category-recommend-dynamic/CategoryRecommendDynamicNew'),
  // SBC 品类热度推荐 占位&产品化 组件
  'CategoryRecommendPlaceholder': () => import(/* webpackChunkName: "ccc-category-recommend-placeholder" */'../components/category-recommend-placeholder/CategoryRecommendPlaceholder'),
  // 二分之一组件
  'HalfComponentContainer': () => import(/* webpackChunkName: "ccc-half-comp" */'../components/half'),
  // 品类品牌单品
  'CategoryBrandItem': () => import(/* webpackChunkName: "ccc-category-brand-item" */'../components/store-page/category-brand-item/index.vue'),
  // 单品
  'SingleProduct': () => import(/* webpackChunkName: "ccc-single-product" */'../components/store-page/single-product/index.vue'),
  // 活动banner组件
  'ActivityBanner': () => import(/* webpackChunkName: "ccc-activity-banner" */'../components/store-page/activity-banner/index.vue'),
  // 大banner组件(单活动、多活动)
  'StorePageSingleActivityBanner': () => import(/* webpackChunkName: "ccc-single-activity-banner" */'../components/store-page/activity-banner/single-activity-banner'),
  // 品牌展示组件
  'BrandDisplay': () => import(/* webpackChunkName: "ccc-brand-display" */'../components/store-page/brand-display-comp/index'),
  // 品类展示组件
  'CategoryDisplay': () => import(/* webpackChunkName: "ccc-category-display" */'../components/store-page/category-display-comp/index'),
  // 搭配展示组件
  'CollocationDisplay': () => import(/* webpackChunkName: "ccc-collocation-display" */'../components/store-page/collocation-display-comp/index'),
  // 直降组件
  'FallList': () => import(/* webpackChunkName: "ccc-fall-list" */'../components/fall-list/FallList.vue'),
  // 节省组件
  'SaveList': () => import(/* webpackChunkName: "ccc-save-list" */'../components/fall-list/SaveList.vue'),
  // 新排行榜落地页
  'HotRankingList': () => import(/* webpackChunkName: "ccc-hot-ranking-list" */'../components/rank-hot/index.vue'),
  // 品牌馆 - 横滑组件
  'BrandPavilionSideSlip': () => import(/* webpackChunkName: "ccc-brand-pavilion-side-slip" */'../components/brand-pavilion/SideSlip.vue'),
  // 品牌墙
  'BrandWall': () => import(/* webpackChunkName: "ccc-static-image" */'../components/brand-wall/BrandWall.vue'),
  // a~z品牌列表
  'AZBrandList': () => import(/* webpackChunkName: "ccc-az-brand-list" */'../components/az-brand-list/index.vue'),
  // 品牌新商品流组件
  'BrandItemsList': () => import(/* webpackChunkName: "ccc-brand-items-list" */'../components/brand-items-list/index.vue'),
  // 品牌馆页头组件
  'BrandHeader': () => import(/* webpackChunkName: "brand-header" */'../components/brand-header/index.vue'),
  // 新人活动入口
  'BrandNewusers': () => import(/* webpackChunkName: "ccc-brand-newusers" */'../components/brand-newusers/index.vue'),
  // 趋势组件
  'trendItems': () => import(/* webpackChunkName: "ccc-trend-entry" */'../components/trend-entry/index.vue'),
  // 设计师组件
  'SheinXList': () => import(/* webpackChunkName: "ccc-shein-x-list" */'../components/shein-x-list/index.vue'),
  // 设计师投票组件
  'SheinXVote': () => import(/* webpackChunkName: "ccc-shein-x-vote" */'../components/shein-x-vote/index.vue'),
  // 通用搜索
  'UniversalSearch': () => import(/* webpackChunkName: "ccc-universal-search" */'../components/universal-search/index.vue'),
  'StoreSideslipImage': () => import(/* webpackChunkName: "ccc-store-sideslip-image" */'../components/store-page/sideslip-image-with-abt/index.vue'),
  // 店铺纵向列表
  'StoreVerticalItems': () => import(/* webpackChunkName: "ccc-vertical-items" */'../components/store-page/verticalItems/index.vue'),
  // 店铺横向列表
  'StoreHorizontalItems': () => import(/* webpackChunkName: "ccc-horizontal-items" */'../components/store-page/HorizontalItems.vue'),
  // 转盘抽奖组件
  'DrawSpin': () => import(/* webpackChunkName: "ccc-draw-spin" */'../components/draw-spin/DrawSpin.vue'),
  // 新闻板块组件
  'NewsSection': () => import(/* webpackChunkName: "ccc-news-section" */'../components/news-section/NewsSection.vue'),
  // 推客券包组件
  'NewUserPackage': () => import(/* webpackChunkName: "ccc-newuser-package" */'../components/newuser-package/NewuserPackage.vue'),
  // 新客
  'NewUserHome': () => import(/* webpackChunkName: "new-user-home" */'../components/new-user-home/NewUserHome.vue'),

  // ===== 以下只写动态组件 =====
  // 动态组件 - 横滑多图 - 多TAB、整屏滑动
  'SideslipImageTabDynamic': () => import(/* webpackChunkName: "ccc-sideslip-image-dynamic-tabs" */'../components/sideslip-image-dynamic/SideSlipTabsCompose.vue'),
  // 动态组件 - 横滑多图 - 无极横滑
  'SideslipImageMarqueeDynamic': () => import(/* webpackChunkName: "ccc-sideslip-image-dynamic-marquee" */'../components/sideslip-image-dynamic/SideSlipMarqueeCompose.vue'),
  // 动态组件 - 闪购 
  'FlashSaleDynamic': () => import(/* webpackChunkName: "flash-sale-dynamic" */'../components/flash-sale-dynamic/FlashSaleDynamic.vue'),
  // 动态组件 - 左图闪购 
  'LeftFlashSaleDynamic': () => import(/* webpackChunkName: "flash-sale-dynamic" */'../components/flash-sale-dynamic/FlashSaleDynamic.vue'),
  // 动态组件 - 横向商品
  'HorizontalItemsDynamic': () => import(/* webpackChunkName: "ccc-horizontal-items-dynamic" */'../components/horizontal-items-dynamic/HorizontalItemsDynamic.vue'),
  // 动态组件 - 单双券入口
  'CodeDynamic': () => import(/* webpackChunkName: "ccc-code-dynamic" */'../components/coupon-dynamic/SingleCoupon.vue'),
  'DoubleCodesDynamic': () => import(/* webpackChunkName: "ccc-double-codes-dynamic" */'../components/coupon-dynamic/DoubleCoupon.vue'),
  // 横滑动3.5图
  'SideslipImageDynamic': () => import(/* webpackChunkName: "ccc-sideslip-image-dynamic" */'../components/sideslip-image-dynamic/SideSlipCompose.vue'),
  // 单图 三图
  'DynamicImage': () => import(/* webpackChunkName: "ccc-dynamic-image" */'../components/static-image-dynamic/StaticImageFactory'),
  // 沉浸式banner
  'ImmersiveBanner': () => import(/* webpackChunkName: "ccc-immersive-banner" */'../components/immersive-banner/index.vue'),
  // 三档券包
  CouponPackage,
  // 横滑自选商品组件
  SelectedHorizontalProductsDynamic: () => import(/* webpackChunkName: "ccc-product-items" */'../components/store-page/product-item-optional/index.vue'),
  // 不贵组件
  'SuperDeals': () => import(/* webpackChunkName: "ccc-super-deals" */'../components/super-deals/SuperDeals.vue'),
  // 店铺dailynew商品列表组件
  'StoreDailynew': () => import(/* webpackChunkName: "ccc-store-daily-new" */'../components/store-page/store-daily-new/index.vue'),
  // 店铺lookbook组件
  'StoreLookbook': () => import(/* webpackChunkName: "ccc-store-lookbook" */'../components/store-page/store-lookbook/LookBook.vue'),
  // 品类热度推荐(店铺)
  'StoreCategoryRecommend': () => import(/* webpackChunkName: "ccc-store-category-recommend" */'../components/store-page/store-category-recommend/index.vue'),
  // 购物车悬浮球
  'CartIcon': () => import(/* webpackChunkName: "ccc-cart-icon" */'../components/cart-icon/CartIcon.vue'),
  // 历史最低价组件
  'LowestPrice': () => import(/* webpackChunkName: "ccc-lowest-price" */'../components/lowest-price/index.vue'),
  // 品牌品类轮播组件
  'CategorySlide': () => import(/* webpackChunkName: "ccc-category-slide" */'../components/category-slide/CategorySlide.vue'),
  // 店铺闪购组件
  'StoreFlashSale': () => import(/* webpackChunkName: "ccc-store-flash-sale" */'../components/store-page/store-flash-sale/StoreFlashSale.vue'),
  // 店铺折扣商品组件
  'StoreDiscountProductsRecommend': () => import(/* webpackChunkName: "ccc-store-flash-sale" */'../components/store-page/store-discount-products-recommend/index.vue'),
  // 店铺关注引导组件
  'StoreFollow': () => import(/* webpackChunkName: "ccc-store-follow" */'../../../store_pages/components/FlowStoreFollowing.vue'),
  // 店铺时尚组件
  // 'TrendStoreRecommend': () => import(/* webpackChunkName: "ccc-trend-store-recommend" */'../components/trend-store-recommend/Index.vue'),
  // 店铺视频组件
  'StoreVideo': () => import(/* webpackChunkName: "ccc-store-video" */'../components/store-page/store-video/StoreVideo.vue'),
  'MultiLine': () => import(/* webpackChunkName: "ccc-multi-line" */'../components/multi-line/Index.vue'),
  // 店铺装修热销榜
  'StoreRankHotSale': () => import(/* webpackChunkName: "ccc-store-rank-hot-sale" */'../components/store-page/rank-hot-sale/RankHotSale.vue'),
  // 店铺热搜词组件
  'StoreHotSearchtermRecommend': () => import(/* webpackChunkName: "ccc-store-hot-search" */'../components/store-page/store-hot-search/StoreHotSearch.vue'),
  // 店铺装修上新
  'StoreNewArrivalList': () => import(/* webpackChunkName: "ccc-store-new-arrival-list" */'../components/store-page/new-arrival-list/NewArrivalList.vue'),
  // 首页凑单券
  'PurchaseCouponBanner': () => import(/* webpackChunkName: "ccc-purchase-coupon-banner" */'../components/purchase-coupon-banner/index.vue'),
  // 专题大促不贵组件
  'ActiveSuperDeals': () => import(/* webpackChunkName: "ccc-super-deals" */'../components/active-super-deals/SuperDeals.vue'),
}

const components = Object.entries(componentsMap).reduce((prev, cur) => {
  prev[`${cur[0]}`] = lazyComponent({
    // propWhole: true,
    componentFactory: cur[1],
    customProps: {
      index: 'number',
      propData: 'object',
      propHooks: 'object',
      content: 'array',
      context: 'object',
      sceneData: 'object',
      cateLinks: 'object',
      language: 'object',
      brand: 'string',
      isLast: 'boolean',
      isLoad: 'boolean',
      lazyForceShow: 'boolean'
    }
  })
  if (['HorizontalAnchor', 'HorizontalAnchorImage', 'Welfare', 'CouponPackage', 'NewUserPackage'].includes(cur[0])) {
    prev[`${cur[0]}`] = cur[1]
  }
  return prev
}, {})
components['ClientOnly'] = ClientOnly

export default components
